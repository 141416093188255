import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "warning"];

  connect() {
    console.log("✅ immo-pourcentage controller connected!");
    this.validate(); // <-- Run validation immediately when the form loads
  }

  validate() {
    console.log("🛠 Validating immo_pourcentage:", this.inputTarget.value);

    if (this.inputTarget.value.trim() === "") {
      console.log("❌ Field is empty, showing warning");
      this.warningTarget.style.display = "block";
    } else {
      console.log("✅ Field has a value, hiding warning");
      this.warningTarget.style.display = "none";
    }
  }
}
